<template>
  <div style="min-height: calc(100vh - 70px);padding: 10px">
    <el-row :gutter="20">
      <el-col :span="11" :offset="6">
        <el-card v-for="item in tableData" :key="item.id" style="margin: 10px 0">
          <div style="display: flex" >
            <div style="padding-left: 50px; flex: 1">
              <div style="border-bottom: 1px solid #ddd; width: 100%; padding-bottom: 10px">
                <span style="font-size: 24px" @click="$router.push('/front/homeDetail?id=' + item.book.id)">{{ item.book.name }}</span>
              </div>
              <div style="line-height: 30px">
                <div><b style="margin-right: 10px">添加时间：</b>{{ item.dateAdded }}</div>
                <div><b style="margin-right: 10px">作者：</b>{{ item.book.author }}</div>
                <div><b style="margin-right: 10px">出版社：</b>{{ item.book.publisher }}</div>
                <div><b style="margin-right: 10px">出版年份：</b>{{ item.book.publishedYear }}</div>
                <div><b style="margin-right: 10px">类型：</b>{{ item.book.typeText.name }}</div>
               <div><b style="margin-right: 10px">所属用户：</b>{{ item.book.user.username+'('+item.book.user.nickname+')' }}</div>
                <div style="text-align: right;" >
                  <el-button type="primary" @click="handleEdit(item)">笔记 <i class="el-icon-edit"></i></el-button>
                  <el-popconfirm
                      class="ml-5"
                      confirm-button-text='确定'
                      cancel-button-text='我再想想'
                      icon="el-icon-info"
                      icon-color="red"
                      title="您确定删除吗？"
                      @confirm="del(item.id)"
                  >
                    <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
                  </el-popconfirm>

                </div>
              </div>
            </div>
          </div>
        </el-card>
        <div style="margin: 10px 0; padding: 10px; background-color: #fff">
          <el-pagination
              size="mid"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-sizes="[2, 5, 10, 20]"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="total">
          </el-pagination>
        </div>
      </el-col>
    </el-row>

    <el-drawer title="信息" :visible.sync="dialogFormVisible" size="50%" :close-on-click-modal="false">
      <div style="margin: 10px 0">
        <el-input style="width: 200px" placeholder="请输入标题" suffix-icon="el-icon-search" v-model="title"></el-input>
        <!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-message" class="ml-5" v-model="email"></el-input>-->
        <!--      <el-input style="width: 200px" placeholder="请输入" suffix-icon="el-icon-position" class="ml-5" v-model="address"></el-input>-->
        <el-button class="ml-5" type="primary" @click="noteDataInfo">搜索</el-button>
        <el-button type="warning" @click="reset">重置</el-button>
      </div>

      <div style="margin: 10px 0">
        <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
        <el-popconfirm
            class="ml-5"
            confirm-button-text='确定'
            cancel-button-text='我再想想'
            icon="el-icon-info"
            icon-color="red"
            title="您确定批量删除这些数据吗？"
            @confirm="delBatch"
        >
          <el-button type="danger" slot="reference">批量删除 <i class="el-icon-remove-outline"></i></el-button>
        </el-popconfirm>
        <!-- <el-upload action="http://localhost:9090/book/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
          <el-button type="primary" class="ml-5">导入 <i class="el-icon-bottom"></i></el-button>
        </el-upload>
        <el-button type="primary" @click="exp" class="ml-5">导出 <i class="el-icon-top"></i></el-button> -->
      </div>

      <el-table :data="noteData" border stripe :header-cell-class-name="'headerBg'"
                @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="bookshelf.book.name" label="书籍" width="120" sortable></el-table-column>
        <el-table-column prop="title" label="标题" width="120" sortable></el-table-column>
        <el-table-column prop="content" label="内容"  sortable></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button type="success" @click="handleNoteEdit(scope.row)">编辑 <i class="el-icon-edit"></i></el-button>
            <el-popconfirm
                class="ml-5"
                confirm-button-text='确定'
                cancel-button-text='我再想想'
                icon="el-icon-info"
                icon-color="red"
                title="您确定删除吗？"
                @confirm="delNote(scope.row.id)"
            >
              <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding: 10px 0">
        <el-pagination
            @size-change="handleNoteSizeChange"
            @current-change="handleNoteCurrentChange"
            :current-page="notePageNum"
            :page-sizes="[2, 5, 10, 20]"
            :page-size="notePageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="noteTotal">
        </el-pagination>
      </div>

      <el-dialog title="笔记" :visible.sync="noteFormVisible" width="60%" :close-on-click-modal="false" :append-to-body="true">
        <el-form ref="dialogForm" :model="noteForm" label-width="100px" size="small" style="width: 90%">
          <el-form-item label="标题">
            <el-input v-model="noteForm.title"></el-input>
          </el-form-item>
          <el-form-item label="内容">
            <mavon-editor ref="md" v-model="noteForm.content" :ishljs="true" @imgAdd="imgAdd"/>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="save">确 定</el-button>
        </div>
      </el-dialog>
    </el-drawer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "MyBookSelf",
  data() {
    return {
      tableData: [],
      noteData:[],
      noteTotal:0,
      notePageNum: 1,
      notePageSize: 5,
      title:"",
      typeBookData: [],
      welcomeBookData:[],
      total: 0,
      pageNum: 1,
      pageSize: 5,
      noteFormVisible:false,
      noteForm:{},
      form: {},
      selfId:null,
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      this.request.get("/bookshelf/my", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    save() {
      this.noteForm.bookshelfId = this.selfId;
      if (!this.noteForm.title) {
        this.$message.warning("请收入标题")
        return;
      }
      this.request.post("/notes", this.noteForm).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.noteFormVisible = false
          this.noteDataInfo()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    noteDataInfo(){
      this.request.get("/notes/my", {
        params: {
          selfId: this.selfId,
          pageNum: this.notePageNum,
          pageSize: this.notePageSize,
          title:this.title
        }
      }).then(res => {
        this.noteData = res.data.records
        this.noteTotal = res.data.total
      })
    },
    handleAdd() {
      this.noteFormVisible = true
      this.noteForm = {}
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    // 绑定@imgAdd event
    imgAdd(pos, $file) {
      let $vm = this.$refs.md
      // 第一步.将图片上传到服务器.
      const formData = new FormData();
      formData.append('file', $file);
      axios({
        url: process.env.VUE_APP_BASE_URL + 'file/upload',
        method: 'post',
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'},
      }).then((res) => {
        console.log("上传成功")
        // 第二步.将返回的url替换到文本原位置![...](./0) -> ![...](url)
        let url = res.data.replace(process.env.VUE_APP_BASE_URL + "file", process.env.VUE_APP_BASE_URL + "file/preview")
        $vm.$img2Url(pos, url);
      })
    },
    del(id) {
      this.request.delete("/bookshelf/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    delNote(id) {
      this.request.delete("/notes/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    delBatch() {
      if (!this.multipleSelection.length) {
        this.$message.error("请选择需要删除的数据")
        return
      }
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/notes/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
      this.selfId = row.id;
      this.noteDataInfo();
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleNoteEdit(row) {
      this.noteForm = JSON.parse(JSON.stringify(row))
      this.noteFormVisible = true
      this.$nextTick(() => {
        if (this.$refs.img) {
          this.$refs.img.clearFiles();
        }
        if (this.$refs.file) {
          this.$refs.file.clearFiles();
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    reset() {
      this.title = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },

    handleNoteSizeChange(pageSize) {
      console.log(pageSize)
      this.notePageSize = pageSize
      this.load()
    },
    handleNoteCurrentChange(pageNum) {
      console.log(pageNum)
      this.notePageNum = pageNum
      this.noteDataInfo()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.img = res
    },
    download(url) {
      window.open(url)
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee !important;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}
</style>
